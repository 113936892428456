/* loginpage.css */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

.login-container {
  padding: 2rem;
}

.login-card {
    width: 100%;
    max-width: 400px;
    padding: 2rem;
    box-shadow: 0 20px 50px rgba(0, 0, 0, 0.1), 0 4px 10px rgba(0, 0, 0, 0.06);
    border-radius: 20px;
    background-color: #ffffff;
    background: linear-gradient(145deg, #f9f9f9, #ffffff);
    border: 1px solid rgba(255, 255, 255, 0.18);
    backdrop-filter: blur(10px);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    position: relative;
    overflow: hidden;
  }
  
  .login-card::before {
    content: "";
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background-color: #e0e0e0;
    background-repeat: no-repeat;
    background-size: 50% 50%, 50% 50%;
    background-position: 0 0, 100% 0, 100% 100%, 0 100%;
    background-image: linear-gradient(#007bff, #007bff), linear-gradient(#007bff, #007bff), linear-gradient(#007bff, #007bff), linear-gradient(#007bff, #007bff);
    animation: rotate 6s linear infinite;
    z-index: 0;
    opacity: 0.05;
  }
  
  .login-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 30px 60px rgba(0, 0, 0, 0.12), 0 10px 20px rgba(0, 0, 0, 0.08);
  }
  
  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  .login-card * {
    position: relative;
    z-index: 1;
  }
  
.login-card:hover {
  transform: translateY(-5px);
}

.login-card .card-title {
  color: #333;
  margin-bottom: 1.5rem;
  text-align: center;
  font-size: 1.75rem;
  font-weight: 600;
}

.login-card .card-text {
  color: #666;
  font-size: 1rem;
  text-align: center;
  margin-bottom: 2rem;
}

.login-btn {
  width: 100%;
  background-color: #007bff;
  border: none;
  color: #ffffff;
  padding: 0.75rem;
  border-radius: 8px;
  font-size: 1.1rem;
  font-weight: 500;
  letter-spacing: 0.05rem;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.login-btn:hover, .login-btn:focus {
  background-color: #0056b3;
  transform: scale(1.02);
}

body {
  font-family: 'Poppins', sans-serif;
  overflow-x: hidden;
}


  